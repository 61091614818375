/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AppThunk } from 'app/store'
import type { Unsubscribe } from 'firebase/firestore'
import {
	collection,
	onSnapshot,
	orderBy,
	query,
	where
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { firestore, functions } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import type { IEmailMessage } from '../interfaces/email.interface'
import { setMessages } from '../redux/emails.slice'

const messagesRef = collection(firestore, 'emails')

export const listenToMessages =
	(threadId: string, showDraft = false): AppThunk<Unsubscribe> =>
	dispatch => {
		const q = query(
			messagesRef,
			where('threadId', '==', threadId),
			orderBy('dateCreated', 'desc')
		)

		const unsubscribe = onSnapshot(
			q,
			querySnapshot => {
				const messages = querySnapshot.docs.map(d => d.data() as IEmailMessage)
				if (showDraft) {
					dispatch(setMessages(messages))
				} else {
					// Temporary fix for draft messages
					// const neededMessages = messages.filter(
					// 	m => !m.labelIds.includes('DRAFT')
					// )
					// dispatch(setMessages(neededMessages))
					dispatch(setMessages(messages))
				}
			},
			error => {
				Errorhandler.captureException(error)
				Logger.error(error)
			}
		)

		return unsubscribe
	}

export interface APIResponse {
	data: any
	success: boolean
	message: string
	error?: string
}

interface ReplyMessageRequest {
	to: string[]
	message: string
	threadId: string
	messageId: string
}

const replyMessageFunction = httpsCallable(functions, 'replyMail')
export const reply = async (data: ReplyMessageRequest) => {
	const response = await replyMessageFunction(data)
	const result = response.data as APIResponse
	return result
}
