import {
	collection,
	doc,
	onSnapshot,
	query,
	setDoc,
	where
} from 'firebase/firestore'

import type { ISpace } from '../interface/spaces.interface'
import { firestore } from 'lib/firebase'
import { nanoid } from 'nanoid'
import { setLoadingSpaces, setSpaces } from '../redux/spaces.slice'
import { slugify } from 'utils/strings'
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { store } from 'app/store'

export class SpacesService {
	private static readonly ref = collection(firestore, 'spaces')

	// Add a new space
	public static async addSpace(space: Partial<ISpace>) {
		const user = store.getState().authentication.authUser

		const data: ISpace = {
			...space,
			funnelCategoryMatch: space.funnelCategoryMatch || 'all',
			workspace: space.workspace || 'companies',
			name: space.name || 'Space',
			slug: space.slug || slugify(space.name || 'Space'),
			id: space.id || nanoid(),
			color: space.color || 'black',
			creator: {
				id: user?.id || '',
				name: user?.name || '',
				photoUrl: user?.photoUrl || ''
			},
			fund: {
				id: user?.fund.id || '',
				name: user?.fund.name || '',
				slug: user?.fund.slug || ''
			},
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			isArchived: false
		}

		const ref = doc(this.ref, data.id)

		await setDoc(ref, data)
	}

	// Update a space
	public static async updateSpace(space: Partial<ISpace>) {
		const ref = doc(this.ref, space.id)
		await setDoc(ref, space, { merge: true })
		return space
	}

	// Archive a space
	public static async archiveSpace(id: string) {
		const ref = doc(this.ref, id)
		await setDoc(ref, { isArchived: true }, { merge: true })
	}

	// Listen to all spaces
	public static listenToSpaces({ fundId }: { fundId: string }) {
		const q = query(this.ref, where('fund.id', '==', fundId))

		store.dispatch(setLoadingSpaces(true))
		return onSnapshot(q, querySnapshot => {
			const spaces = querySnapshot.docs.map(d => d.data() as ISpace)
			store.dispatch(
				setSpaces(
					spaces
						.sort((a, b) => {
							const aRank =
								a.index === undefined ? Number.POSITIVE_INFINITY : a.index
							const bRank =
								b.index === undefined ? Number.POSITIVE_INFINITY : b.index
							return aRank - bRank
						})
						.filter(space => !space.isArchived)
				)
			)
		})
	}
}
