/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react'
import clsx from 'clsx'
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar'
import type { ICalendarEvent } from 'features/companies/interfaces/calendarEvent.interface'
import { renderDateAndTime } from 'utils/date.util'

function PastEventsAccordion({ pastEvents }: { pastEvents: ICalendarEvent[] }) {
	const [isOpen, setIsOpen] = useState(false)

	// Toggle accordion open/close
	const toggleAccordion = () => {
		setIsOpen(!isOpen)
	}

	return (
		<div className='mt-3 rounded-md border p-3'>
			<div
				className='flex cursor-pointer items-center gap-1'
				onClick={toggleAccordion}
			>
				<div
					className={clsx(
						'h-3 w-3 rounded-full',
						isOpen ? 'bg-blue-500' : 'bg-gray-500'
					)}
				/>
				<div className='text-sm font-semibold text-blue-700'>Past Events</div>
				<div className='ml-auto text-sm font-semibold text-gray-700'>
					{isOpen ? '▲' : '▼'} {/* Arrow to indicate toggle */}
				</div>
			</div>

			{/* Conditional rendering for showing/hiding events */}
			{isOpen && (
				<div
					className={clsx(
						'overflow-hidden transition-all duration-300 ease-in-out',
						isOpen ? 'max-h-screen overflow-y-auto' : 'max-h-0'
					)}
				>
					{pastEvents.length > 0 && (
						<div>
							{pastEvents.map((event: ICalendarEvent) => (
								<div
									key={event.id}
									className='mb-3 flex flex-col items-center gap-2 rounded-md border-b p-2'
								>
									<div className='flex w-full items-center justify-start gap-2'>
										<Avatar className='h-8 w-8 border'>
											{event.user.photoUrl && (
												<AvatarImage
													src={event.user.photoUrl || '/images/logo/Logo-4.png'}
												/>
											)}
											<AvatarFallback>{event.user.name[0]}</AvatarFallback>
										</Avatar>
										<div className='line-clamp-2 flex-1 text-sm font-semibold text-blue-700'>
											{event.summary}
										</div>
										<div className='text-xs font-semibold text-gray-600'>
											{renderDateAndTime(
												event.start.dateTime || event.start.date || ''
											)}
										</div>
									</div>
									<div className='w-full'>
										{event.attendees.map(attendee => (
											<div key={attendee.email} className='text-xs'>
												{attendee.email}
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					)}
					{/* ) : (
            <div className="text-sm font-semibold text-red-500">
              No past events
            </div>
          )} */}
				</div>
			)}
		</div>
	)
}

export default PastEventsAccordion
