import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Suspense, lazy } from 'react'
import { Toaster } from 'sonner'
import { TooltipProvider } from 'components/ui/tooltip'
import ProtectedRoute from 'routes/Protected'
import { AuthProvider } from 'context/AuthContext'
import FeatureAccessContextProvider from 'features/authorization/context/FeatureAccessProvider'
import NotFound from 'components/NotFound'
import { UserRole } from 'interfaces'
import Home from 'pages/Home'
import DashboardPage from 'features/dashboard/view/DashboardPage'

// Lazy-loaded components
// const Home = lazy(() => import('pages/Home'))
const AuthPage = lazy(() => import('features/authentication/view/AuthPage'))
const Login = lazy(() => import('features/authentication/view/Login'))
const OnboardingPage = lazy(
	() => import('features/onboarding/view/OnboardingPage')
)
const UnauthorizedPage = lazy(
	() => import('features/authentication/view/UnauthorizedPage')
)
const OnboardCompany = lazy(() => import('pages/OnboardCompany'))
const SharedLink = lazy(() => import('pages/SharedLinkDetails'))
const TermsAndConditions = lazy(() => import('pages/TermsAndConditions'))
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicy'))
const Mail = lazy(() => import('pages/Mail'))
const Prospects = lazy(() => import('pages/Prospects'))
const ArchivedFunnels = lazy(
	() =>
		import(
			'features/companies/submodules/ArchivedFunnels/views/ArchivedFunnels'
		)
)
const LimitedPartnerDetails = lazy(() => import('pages/LimitedPartnerDetails'))
const NotificationsPage = lazy(
	() => import('features/notifications/view/NotificationsPage')
)
const Companies = lazy(() => import('pages/Companies'))
const PortfolioCompanies = lazy(
	() => import('features/companies/view/PortfolioCompanies')
)
const CompanyDetails = lazy(
	() => import('features/companies/view/CompanyDetails')
)
const Dev = lazy(() => import('features/dev/view/dev'))
const Contacts = lazy(() => import('pages/Contacts'))
const ContactDetails = lazy(
	() => import('features/contacts/view/ContactDetails')
)
const Tasks = lazy(() => import('pages/Tasks'))
const LimitedPartners = lazy(() => import('pages/LimitedPartners'))
const Reports = lazy(() => import('pages/Reports'))
const SuperAdmin = lazy(() => import('pages/SuperAdmin'))
const CapitalCalls = lazy(() => import('pages/CapitalCall'))
const NewCapitalCallPage = lazy(
	() => import('features/new-capital-call/views/NewCapitalCallPage')
)
const Settings = lazy(() => import('features/settings/view/Settings'))
const InvitationPage = lazy(
	() => import('features/invitations/view/InvitationPage')
)
// const DashboardPage = lazy(
// 	() => import('features/dashboard/view/DashboardPage')
// )
const ProfilePage = lazy(() => import('features/profile/views/Profile'))

// // Lazy-load Quill and register modules
// const Quill = lazy(async () => {
// 	const { default: Quill, Mention, MentionBlot } = await import('quill-mention')
// 	Quill.register(
// 		{ 'blots/mention': MentionBlot, 'modules/mention': Mention },
// 		true
// 	)
// 	return Quill
// })

export default function App() {
	return (
		<BrowserRouter>
			<DndProvider backend={HTML5Backend}>
				<AuthProvider>
					<FeatureAccessContextProvider>
						<TooltipProvider delayDuration={50}>
							<Suspense fallback={<div />}>
								<Toaster />
								<Routes>
									<Route path='/' element={<Home />} />
									<Route path='/auth' element={<AuthPage />} />
									<Route path='/login' element={<Login />} />
									<Route path='/onboarding' element={<OnboardingPage />} />
									<Route path='/unauthorized' element={<UnauthorizedPage />} />
									<Route path='/create-company' element={<OnboardCompany />} />
									<Route path='/shared/:id' element={<SharedLink />} />
									<Route
										path='/terms-and-conditions'
										element={<TermsAndConditions />}
									/>
									<Route
										path='/privacy-policy'
										element={<PrivacyPolicyPage />}
									/>
									<Route
										path='/mail'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<Mail />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/prospects'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<Prospects />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/prospects/:id'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<LimitedPartnerDetails />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/prospects/archived'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<ArchivedFunnels entities={['prospect']} />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/notifications'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<NotificationsPage />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/companies'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<Companies />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/_companies'
										element={
											<ProtectedRoute
												allowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<PortfolioCompanies />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/company/:id'
										element={
											<ProtectedRoute>
												<CompanyDetails />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/companies/archived'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<ArchivedFunnels entities={['company']} />
											</ProtectedRoute>
										}
									/>
									{process.env.NODE_ENV === 'development' && (
										<Route
											path='/dev/franciseshun'
											element={
												<ProtectedRoute>
													<Dev />
												</ProtectedRoute>
											}
										/>
									)}
									<Route
										path='/contacts'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<Contacts />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/contact/:id'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<ContactDetails />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/tasks'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<Tasks />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/limited-partners'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.VenturePartner,
													UserRole.TeamMember
												]}
											>
												<LimitedPartners />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/limited-partner/:id'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.VenturePartner,
													UserRole.TeamMember
												]}
											>
												<LimitedPartnerDetails />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/reports'
										element={
											<ProtectedRoute>
												<Reports />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/god'
										element={
											<ProtectedRoute allowedRoles={[UserRole.SuperAdmin]}>
												<SuperAdmin />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/capital-call'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.VenturePartner,
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<CapitalCalls />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/capital-call/new'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.VenturePartner,
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<NewCapitalCallPage />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/settings'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<Settings />
											</ProtectedRoute>
										}
									/>
									<Route path='/invitations/:id' element={<InvitationPage />} />
									<Route
										path='/dashboard'
										element={
											<ProtectedRoute
												notAllowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<DashboardPage />
											</ProtectedRoute>
										}
									/>
									<Route
										path='/_'
										element={
											<ProtectedRoute
												allowedRoles={[
													UserRole.LimitedPartner,
													UserRole.TeamMember
												]}
											>
												<ProfilePage />
											</ProtectedRoute>
										}
									/>
									<Route path='*' element={<NotFound />} />
								</Routes>
							</Suspense>
						</TooltipProvider>
					</FeatureAccessContextProvider>
				</AuthProvider>
			</DndProvider>
		</BrowserRouter>
	)
}
