/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-nested-ternary */
import LoadingOrError from 'components/LoadingOrError'
import {
	getAuthUser,
	listenToAuthUser,
	listenToAuthUserFund
} from 'features/authentication/authentication.api'
import { WorkspaceService } from 'features/authentication/service/workspace.service'
import { AppStatusService } from 'features/status/status.service'
import { onAuthStateChanged, type User as FirebaseUser } from 'firebase/auth'
import type { Unsubscribe } from 'firebase/firestore'
import { UserRole, type User } from 'interfaces'
import { auth } from 'lib/firebase'
import type { ReactElement } from 'react'
import { createContext, useEffect, useMemo, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

export const AuthContext = createContext({
	authUser: null as User | null,
	authLoading: false,
	authError: null as string | null,
	setAuthError: (error: string | null) => { }
})

export function AuthProvider({
	children
}: {
	children: React.ReactNode
}): ReactElement {
	// const location = useLocation()
	const navigate = useNavigate()

	const [authUser, setAuthUser] = useState<User | null>(null)
	const [authError, setAuthError] = useState<string | null>(null)
	const [authLoading, setAuthLoading] = useState(true)
	const [isNewUser, setIsNewUser] = useState(false)

	const loginClicked = localStorage.getItem('loginClicked')
	const signupClicked = localStorage.getItem('signupClicked')

	// useEffect(() => {
	// 	if (!authUser?.id) return

	// 	AppStatusService.getStatuses()

	// 	const unsubscribeUser = listenToAuthUser(authUser.id)
	// 	const unsubscribeFund = listenToAuthUserFund(authUser.fund.id)
	// 	const unsubscribeWorkspaces = WorkspaceService.listenToWorkspaces(authUser)

	// 	return () => {
	// 		unsubscribeUser()
	// 		unsubscribeFund()
	// 		unsubscribeWorkspaces()
	// 	}
	// }, [authUser?.id])

	useEffect(() => {
		if (!authUser?.id) return

		AppStatusService.getStatuses()

		const unsubscribeUser = listenToAuthUser(authUser.id)
		const unsubscribeFund = listenToAuthUserFund(authUser.fund.id)

		let unsubscribeWorkspaces: Unsubscribe | null
		if (
			![UserRole.TeamMember, UserRole.LimitedPartner].includes(authUser.role)
		) {
			unsubscribeWorkspaces = WorkspaceService.listenToWorkspaces(authUser)
		}
		return () => {
			unsubscribeUser()
			unsubscribeFund()

			if (unsubscribeWorkspaces) {
				unsubscribeWorkspaces()
			}
		}
	}, [authUser?.id])

	useEffect(() => { }, [])

	const handleOnAuthStateChanged = async (
		firebaseUser: FirebaseUser | null
	): Promise<any> => {
		try {
			setAuthLoading(true)

			if (!firebaseUser) throw new Error('Account not found')

			// Check is last login is the same as current login
			if (
				firebaseUser.metadata.lastSignInTime ===
				firebaseUser.metadata.creationTime
			) {
				// Go to onboarding page
				// navigate(`/onboarding`)
				setIsNewUser(true)
				return
			}

			const user = await getAuthUser()

			// if (!user?.fund) navigate(`/onboarding`)
			if (!user) throw new Error('Account not found')
			setAuthUser(user)
		} catch (error: any) {
			if (loginClicked === 'yes' || signupClicked === 'yes') {
				setAuthError(error.message ?? 'An error occurred')
			}

			setAuthUser(null)
		} finally {
			setAuthLoading(false)
			localStorage.removeItem('loginClicked')
			localStorage.removeItem('signupClicked')
		}
	}

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		const unsubscribe = onAuthStateChanged(auth, handleOnAuthStateChanged)

		return () => unsubscribe()
	}, [])

	const memoizedValue = useMemo(
		() => ({
			authUser,
			authLoading,
			authError,
			setAuthError
		}),
		[authUser, authLoading, authError, setAuthError]
	)

	if (isNewUser) {
		return <Navigate to="/onboarding" />
	}

	// eslint-disable-next-line unicorn/prefer-set-has
	const unprotectedRoutes = ['/', '/login']

	if (unprotectedRoutes.includes(location.pathname)) {
		return (
			<AuthContext.Provider value={memoizedValue}>
				{children}
			</AuthContext.Provider>
		)
	}

	return (
		<AuthContext.Provider value={memoizedValue}>
			{authLoading ? <LoadingOrError /> : children}
		</AuthContext.Provider>
	)
}
