import type { Partner, User } from "interfaces";
import { collection, doc, onSnapshot, query, type Unsubscribe, updateDoc, where } from "firebase/firestore";
import { setActiveWorkspace, setWorkspaces } from "../authentication.slice";

import Errorhandler from "lib/sentry";
import Logger from "lib/logger";
import type { Workspace } from "../interfaces/workspace.interface";
import { firestore } from "lib/firebase";
import { store } from "app/store";
import { toast } from "sonner";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class WorkspaceService {
  private static readonly usersRef = collection(firestore, 'users');

  private static readonly partnersRef = collection(firestore, 'partners');

  // public static listenToWorkspaces(user: User): () => void {    
  //   const q = query(this.partnersRef, where('userId', '==', user.id));

  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     const partners = snapshot.docs.map((i) => i.data() as Partner);
  //     const workspaces = partners.map((partner) => {

  //       const workspace: Workspace = {
  //         user: {
  //           id: partner.userId,
  //           name: user.name,
  //           photoUrl: user.photoUrl || ''
  //         },
  //         fund: partner.fund,
  //         role: partner.role,
  //       };
  //       return workspace;
  //     });
  //     store.dispatch(setWorkspaces(workspaces));

  //     const activeWorkspace = workspaces.find((i) => i.fund.id === user.fund.id);
  //     if (!activeWorkspace) {
  //       Logger.error('Active workspace not found');
  //       return;
  //     }
  //     store.dispatch(setActiveWorkspace(activeWorkspace));
  //   }, (error) => {
  //     Logger.error('Error getting partners', error);
  //     Errorhandler.captureException(error);
  //   });

  //   return unsubscribe;
  // }

  public static listenToWorkspaces(user: User): Unsubscribe {
    const q = query(this.partnersRef, where('userId', '==', user.id))

    const unsubscribe = onSnapshot(
      q,
      snapshot => {
        const partners = snapshot.docs.map(i => i.data() as Partner)
        const workspaces = partners.map(partner => {
          const workspace: Workspace = {
            user: {
              id: partner.userId,
              name: user.name,
              photoUrl: user.photoUrl || ''
            },
            fund: partner.fund,
            role: partner.role
          }
          return workspace
        })
        store.dispatch(setWorkspaces(workspaces))

        const activeWorkspace = workspaces.find(i => i.fund.id === user.fund.id)
        if (!activeWorkspace) {
          Logger.error('Active workspace not found')
          return
        }
        store.dispatch(setActiveWorkspace(activeWorkspace))
      },
      error => {
        Logger.error('Error getting partners', error)
        Errorhandler.captureException(error)
      }
    )

    return unsubscribe
  }

  public static async setActiveWorkspace(workspace: Workspace): Promise<void> {
    // update the user's active workspace
    store.dispatch(setActiveWorkspace(workspace));

    // update the user's active fund
    const docRef = doc(firestore, `users/${workspace.user.id}`);
    await updateDoc(docRef, {
      fund: workspace.fund,
      role: workspace.role
    });

    toast.success('Workspace updated successfully');
  }

}