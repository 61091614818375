/* eslint-disable react/button-has-type */
import { Calendar, Kanban, ListTree, Rows3, Table2 } from 'lucide-react'

import DashboardComments from './DashboardComments'
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import DashboardMeetings from './DashboardMeetings'
import DashboardNotes from './DashboardNotes'
import DashboardUpdates from './DashboardUpdates'
import KeepUpWithCompanies from 'features/KeepUpWithCompanies/view/KeepUpWithCompanies'
import type { ReactElement } from 'react'
import usePageTimeTracking from 'hooks/usePageTracking'
import useTabs from 'components/hooks/useTabs'
import { useAppSelector } from 'app/hooks'
import { selectAuthUser } from 'features/authentication/authentication.slice'

export default function DashboardPage(): ReactElement {
	usePageTimeTracking({ pageName: 'Dashboard' })

	const authUser = useAppSelector(selectAuthUser)

	const tabs = [
		{
			name: 'All',
			value: 'all',
			icon: <Rows3 size={15} />
		},
		{
			name: 'Notes',
			value: 'notes',
			icon: <Kanban size={15} />
		},
		{
			name: 'Comments',
			value: 'comments',
			icon: <ListTree size={15} />
		},
		{
			name: 'Updates',
			value: 'updates',
			icon: <Table2 size={15} />
		},
		{
			name: 'Meetings',
			value: 'meetings',
			icon: <Calendar size={15} />,
			disabled: false,
			isLoading: authUser?.isProcessing?.googleCalendar
		}
	]

	const { Tabs, activeTab } = useTabs({
		tabs,
		containerClassName: 'mt-3 text-xs',
		urlPathName: 'update',
		activeTabClassName: 'rounded-full bg-blue-700 text-white py-1',
		inactiveTabClassName: 'rounded-full bg-gray-100 text-black py-1'
	})

	return (
		<DashboardLayout hideHeader={false} showSidebar title='Dashboard'>
			<main className='px-5 py-3'>
				<div className='text-lg font-semibold text-blue-700'>
					Recent Updates
				</div>
				<Tabs />
				<KeepUpWithCompanies />
				<div className='mt-5 grid w-full gap-5 lg:grid-cols-2'>
					<div className='fle-1'>
						{activeTab.value === 'all' && (
							<div>
								<div className='mb-2 text-sm font-semibold text-blue-700'>
									Notes
								</div>
								<DashboardNotes />
								<div className='my-2 text-sm font-semibold text-blue-700'>
									Comments
								</div>
								<DashboardComments />
								<div className='my-2 text-sm font-semibold text-blue-700'>
									Updates
								</div>
								<DashboardUpdates />
								<div className='my-2 text-sm font-semibold text-blue-700'>
									Meetings
								</div>
								<DashboardMeetings />
							</div>
						)}
						{activeTab.value === 'notes' && <DashboardNotes />}
						{activeTab.value === 'comments' && <DashboardComments />}
						{activeTab.value === 'updates' && <DashboardUpdates />}
						{activeTab.value === 'meetings' && <DashboardMeetings />}
					</div>
					{/* <div className='flex-1' /> */}
				</div>
			</main>
		</DashboardLayout>
	)
}
