import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
} from 'components/ui/select'

import { WorkspaceService } from '../service/workspace.service'
import { nanoid } from 'nanoid'
import { useAppSelector } from "app/hooks"

export default function WorkSpaceSelector(): JSX.Element {
  const { workspaces, activeWorkspace } = useAppSelector(state => state.authentication)

  async function onSelect(value: string) {
    const workspace = workspaces.find(w => w.fund.id === value);
    if (!workspace) return;
    if(workspace.fund.id === activeWorkspace?.fund.id) return;
    await WorkspaceService.setActiveWorkspace(workspace)

    // relaod the page
    window.location.reload()
  }

  return (
    <div className='flex gap-2 w-[12.2rem]' data-testid="workspace-selector">
      	<Select
          defaultValue={activeWorkspace?.fund.id}
          onValueChange={e => {
            onSelect(e)
          }}
        >
        <SelectTrigger
          defaultValue='onlyContent'
          className='w-fit  border-none border-transparent shadow-none focus:outline-none focus:ring-0'
        >
          <div className="flex items-center gap-3 ">
            <img
              src={activeWorkspace?.fund.website
                ? `https://www.google.com/s2/favicons?sz=128&domain_url=${activeWorkspace.fund.website}`
                : '/images/logo/Logo-4.png'}
              alt='profile'
              className='h-8 w-8 rounded-full border'
            />
            <div className='flex items-center gap-2'>
              <span className='flex-1 font-semibold'>{activeWorkspace?.fund.name}</span>
            </div>
          </div>
        </SelectTrigger>
				
				<SelectContent className='z-[999999]'>
          <SelectGroup>
            {workspaces.map(option => (
              <SelectItem key={nanoid()} value={option.fund.id}>
                <div className="flex items-center gap-3 ">
                  <img
                    src={option.fund.website
                      ? `https://www.google.com/s2/favicons?sz=128&domain_url=${option.fund.website}`
                      : '/images/logo/Logo-4.png'}
                    alt='profile'
                    className='h-8 w-8 rounded-full border'
                  />
                  <div className='flex items-center gap-2'>
                    <span className='flex-1 font-semibold'>{option.fund.name}</span>
                  </div>
                </div>
              </SelectItem>
            ))}
          </SelectGroup>
				</SelectContent>
			</Select>
    </div>
  )
}