/* eslint-disable react/function-component-definition */
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import type React from 'react'
import { useContext } from 'react'
import { UserRole } from 'interfaces'

interface AuthProps {
	notAllowedRoles?: UserRole[]
	allowedRoles?: UserRole[] | 'all-roles'
	children?: React.ReactNode
}

const RoleGuard: React.FC<AuthProps> = ({
	notAllowedRoles,
	allowedRoles,
	children = null
}) => {
	const { authUser } = useContext(AuthContext)
	const location = useLocation()

	if (
		notAllowedRoles?.includes(authUser?.role as UserRole) ||
		(allowedRoles !== 'all-roles' &&
			!allowedRoles?.includes(authUser?.role as UserRole))
	) {
		if (!authUser) {
			return <Navigate to='/' state={{ from: location }} replace />
		}

		if (
			[UserRole.LimitedPartner, UserRole.TeamMember].includes(authUser.role)
		) {
			return <Navigate to='/_' state={{ from: location }} replace />
		}

		return (
			<Navigate
				to='/companies?space=all&view=board'
				state={{ from: location }}
				replace
			/>
		)
	}

	if (authUser?.name) {
		return children ?? <Outlet />
	}

	return <Navigate to='/register' state={{ from: location }} replace />
}

export default RoleGuard
