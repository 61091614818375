import { addNote, deleteNote, updateNote } from '../api/notes.api'

import { ActivityService } from 'features/activity/service/activity.service'
import { Errorhandler } from 'lib/sentry'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import type { INote } from '../interfaces/notes.interface'
import { Logger } from 'lib/logger'
import { nanoid } from 'nanoid'
/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable unicorn/no-static-only-class */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import { toast } from 'sonner'

export class NoteService {
	public static addNote = async (note: INote): Promise<boolean> => {
		try {
			await addNote(note)

			toast.success('Note added successfully')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return false

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: 'noted on',
				summary: '',
				description: note.title || note.note,
				icon: '',
				createdAt: new Date().toISOString()
			}
			if (note.company) {
				activity.summary = note.company.name
				activity.company = note.company
			}
			if (note.limitedPartner) {
				activity.limitedPartner = note.limitedPartner
				activity.summary = note.limitedPartner.name
			}

			await ActivityService.add(activity)

			return true
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to add note')

			Logger.error(error)
			Errorhandler.captureException(error)

			return false
		}
	}

	public static updateNote = async (note: INote): Promise<boolean> => {
		try {
			await updateNote(note)

			toast.dismiss()
			toast.success('Note updated successfully')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return false

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: 'updated note',
				summary: '',
				description: note.title || note.note,
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (note.company) {
				activity.summary = note.company.name
				activity.company = note.company
			}

			if (note.limitedPartner) {
				activity.limitedPartner = note.limitedPartner
				activity.summary = note.limitedPartner.name
			}

			await ActivityService.add(activity)

			return true
		} catch (error: any) {
			toast.dismiss()
			toast.error(error.message ?? 'Failed to update note')

			Logger.error(error)
			Errorhandler.captureException(error)

			return false
		}
	}

	public static deleteNote = async (note: INote) => {
		try {
			await deleteNote(note.id)

			toast.dismiss()
			toast.success('Note deleted successfully')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: 'deleted note',
				summary: '',
				description: note.title || note.note,
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (note.company) {
				activity.summary = note.company.name
				activity.company = note.company
			}

			if (note.limitedPartner) {
				activity.limitedPartner = note.limitedPartner
				activity.summary = note.limitedPartner.name
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to delete note')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static pinNote = async (note: INote, pinned: boolean) => {
		try {
			const newNote = { ...note, isPinned: pinned }

			await updateNote(newNote)

			toast.dismiss()
			toast.success(pinned ? 'Note pinned' : 'Note unpinned')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: pinned ? 'pinned note' : 'unpinned note',
				summary: '',
				description: note.title || note.note,
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (note.company) {
				activity.summary = note.company.name
				activity.company = note.company
			}

			if (note.limitedPartner) {
				activity.limitedPartner = note.limitedPartner
				activity.summary = note.limitedPartner.name
			}

			if (note.contact) {
				activity.contact = note.contact
				activity.summary = note.contact.name
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.dismiss()
			toast.error(error.message ?? 'Failed to pin note')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}
}
