export enum Feature {
	All = 'all-features',

	// section feature
	LogAction = 'log-action',
	MeetingNotes = 'meeting-notes',
	Notes = 'notes',
	Portfolio = 'portfolio',
	CompanyUpdates = 'company-update',
	Dashboard = 'dashboard',
	Files = 'files',
	CompanyDocuments = 'company-documents',
	CompanyDeck = 'company-deck',
	CompanyInfo = 'company-info',
	TeamMembers = 'team-members',
	LpDetails = 'lp-details',
	CompanyChat = 'company-chat',
	RelevantPeople = 'relavant-people',
	LpRelevantCompanies = 'lp-relevant-companies',
	InvestmentTerms = 'investment-terms',
	InvestmentMemos = 'investment-memos',
	CapitalCalls = 'capital-calls',
	Email = 'email',
	TasksNotes = 'tasks-notes',
	Reminders = 'Reminders',
	CompanyRelevantPeople = 'company-relevant-people',
	CurrencyExchange = 'currency-exchange',

	// Routes
	TasksRoute = 'tasks',
	LpProfile = 'lp-profile',
	ReportsRoute = 'reports',
	ContactsRoute = 'contacts',
	CompaniesRoute = 'companies',
	PortfolioCompaniesRoute = 'portfolio-companies-route'
}
