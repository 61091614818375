import ExchangerUI from 'features/currency_exchange/components/ExchangerUI'
import NotificationsInProgressHeader from 'features/notifications/components/NotificationsInProgressHeader'
import SearchEverything from 'features/search/view/SearchEverything'
import { UserNav } from 'components/UserNav'
import WorkSpaceSelector from '../../features/authentication/view/WorkSpaceSelector'
import { useContext } from 'react'
import { AuthContext } from 'context/AuthContext'
import { UserRole } from 'interfaces'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function DashboardHeader({ headerComponent }: any): JSX.Element {
	const { authUser } = useContext(AuthContext)

	return (
		<div className='fixed z-[9] flex h-12 w-screen items-center justify-between border-b bg-white'>
			<div className='flex items-center'>
				{![UserRole.LimitedPartner, UserRole.TeamMember].includes(
					authUser?.role as UserRole
				) && <WorkSpaceSelector />}

				{[UserRole.LimitedPartner, UserRole.TeamMember].includes(
					authUser?.role as UserRole
				) && (
					<div className='w-[12.2rem] pl-3' data-testid='app-logo'>
						<img src='/images/logo/Logo-1.png' className='h-6' alt='logo' />
					</div>
				)}

				{headerComponent && <div>{headerComponent}</div>}
			</div>
			<div className='flex items-center gap-4'>
				<ExchangerUI />

				{![UserRole.TeamMember, UserRole.LimitedPartner].includes(
					authUser?.role as UserRole
				) && <SearchEverything />}

				{![UserRole.TeamMember, UserRole.LimitedPartner].includes(
					authUser?.role as UserRole
				) && <NotificationsInProgressHeader />}

				<p className='text-xs'>
					{authUser?.role.replaceAll('-', ' ').toUpperCase()}
				</p>
				<UserNav />
			</div>
		</div>
	)
}
