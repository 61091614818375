/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable unicorn/consistent-function-scoping */
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import type { ICompany } from 'interfaces'
import { Link } from 'react-router-dom'
import { ScrollArea } from 'components/ui/scroll-area'
import { subMonths } from 'date-fns'
import { firestore } from 'lib/firebase'

export default function KeepUpWithCompanies() {
	const { authUser } = useContext(AuthContext)
	const [companies, setCompanies] = useState<ICompany[]>([])

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (!authUser?.fund.id) return () => {}
		
		function listenToKeepUpWithCompanies() {
			const col = collection(firestore, 'companies')
			const date = subMonths(new Date(), 3).toISOString()
			const q = query(
				col,
				where('fund.id', '==', authUser?.fund.id),
				where('funnel.category', '==', 'portfolio'),
				where(`lastActivityDate`, '<', date)
			)
			const unsubscribe = onSnapshot(q, snapshot => {
				const data = snapshot.docs.map(doc => doc.data() as ICompany)
				setCompanies(data)
			})
			return unsubscribe
		}

		const unsubscribe = listenToKeepUpWithCompanies()
		return () => unsubscribe()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authUser?.fund.id])

	if (companies.length === 0) {
		return null
	}

	return (
		<div className='mt-5 flex h-full w-full items-center gap-5 rounded-md bg-white dark:bg-gray-700'>
			<div>
				<h2 className='text-base font-semibold text-gray-700'>
					Keep up with companies
				</h2>
				<div className='text-xs text-gray-500 dark:text-white'>
					Pending updates for 1 month or more
				</div>
			</div>

			<div className='flex gap-2'>
				{companies.slice(0, 4).map(company => (
					<Link to={`/company/${company.id}`} key={company.id}>
						<Tooltip key={company.id}>
							<TooltipTrigger asChild>
								<Avatar className='h-8 w-8 cursor-pointer border'>
									<AvatarImage
										src={
											company.website
												? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.website}`
												: '/images/logo/Logo-4.png'
										}
									/>
									<AvatarFallback>{company.name.charAt(0)}</AvatarFallback>
								</Avatar>
							</TooltipTrigger>
							<TooltipContent>View {company.name}</TooltipContent>
						</Tooltip>
					</Link>
				))}

				{companies.length > 4 && (
					<div className='flex h-10 w-10 items-center justify-center rounded-full'>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<span className='cursor-pointer text-xs text-gray-500 dark:text-white'>
									+{companies.length - 4}
								</span>
							</DropdownMenuTrigger>
							<DropdownMenuContent align='start' className='w-fit'>
								<DropdownMenuLabel>All Companies</DropdownMenuLabel>
								<DropdownMenuSeparator />
								<ScrollArea className='flex max-h-96 w-full flex-col'>
									{companies.slice(4).map(company => (
										<Link
											to={`/company/${company.id}`}
											key={company.id}
											className='flex flex-col items-center gap-2 border-b p-2 py-3 hover:bg-gray-100'
										>
											<div className='flex w-full items-center gap-2'>
												<Avatar className='h-8 w-8 rounded-full border'>
													<AvatarImage
														src={
															company.website
																? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.website}`
																: '/images/logo/Logo-4.png'
														}
													/>
													<AvatarFallback>
														{company.name.charAt(0)}
													</AvatarFallback>
												</Avatar>
												<div className='line-clamp-2 flex-1 text-sm font-semibold text-blue-700'>
													{company.name}
												</div>
											</div>
										</Link>
									))}
								</ScrollArea>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				)}
			</div>
		</div>
	)
}
