/* eslint-disable @typescript-eslint/no-shadow */
import { Navigate, useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import { ProtectedServices } from 'services/protected.service'
import type { ReactNode } from 'react'
import { FeatureAccessContext } from 'features/authorization/context/FeatureAccessProvider'
import { FEATURE_PERMISSIONS } from 'features/authorization/data/routeAccessPermissions.data'
import type { UserRole } from 'interfaces'
import RoleGuard from 'features/guards/RoleGuard'

interface ProtectedRouteProps {
	children: ReactNode
	notAllowedRoles?: UserRole[]
	allowedRoles?: UserRole[] | 'all-roles'
}

export default function ProtectedRoute({
	children,
	notAllowedRoles,
	allowedRoles = 'all-roles'
}: Readonly<ProtectedRouteProps>): ReactNode {
	const { authUser, authLoading } = useContext(AuthContext)
	const { setPermissions } = useContext(FeatureAccessContext)

	const [isMaintenanceOn, setIsMaintenanceOn] = useState<boolean | undefined>()

	const location = useLocation()
	const isAuthenticated = !authLoading && authUser

	useEffect(() => {
		ProtectedServices.getMaintenanceStatus().then(({ isMaintenanceOn }) => {
			setIsMaintenanceOn(isMaintenanceOn)
		})
	}, [])

	useEffect(() => {
		if (!authUser) return

		setPermissions(FEATURE_PERMISSIONS[authUser.role])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authUser])

	if (!isAuthenticated) {
		return <Navigate to='/login' state={{ from: location }} replace />
	}

	if (isMaintenanceOn === undefined) {
		return (
			<div className='flex min-h-screen items-center justify-center'>
				<h1 className='text-xl' data-testid='LoadingOrError'>
					<img src='/images/logo/Logo-1.png' className='h-6 w-24' alt='' />
				</h1>
			</div>
		)
	}

	if (isMaintenanceOn) {
		return (
			<div className='container mx-auto flex h-screen w-screen  flex-col items-center justify-center'>
				<img src='/images/logo/Logo-4.png' alt='logo' className='h-20 w-20' />
				<div className='text-center text-xl font-semibold'>
					Our dashboard is currently down for maintanence
				</div>
				<div className='mt-5 text-center'>
					We apologize for any inconveniences caused and we will be back online
					as soon as possible. Please check again in a little while. Thank you!
				</div>
				<div>
					You can reach us at{' '}
					<a
						href='mailto:admin@spok.vc'
						className='font-semibold text-blue-700'
					>
						admin@spok.vc
					</a>
				</div>
			</div>
		)
	}

	return (
		<RoleGuard notAllowedRoles={notAllowedRoles} allowedRoles={allowedRoles}>
			{children}
		</RoleGuard>
	)
}
