import type { Action, ThunkAction } from '@reduxjs/toolkit'
import { combineSlices, configureStore } from '@reduxjs/toolkit'

import { activitySlice } from 'features/activity/redux/activities.slice'
import { appStatusSlice } from 'features/status/status.slice'
import { authenticationSlice } from 'features/authentication/authentication.slice'
import { bankAcountsSlice } from 'features/bank_accounts/redux/bankAccounts.slice'
import { calendarEventsSlice } from 'features/calendar_events/redux/calendar_events.slice'
import { capitalCallRequestsSlice } from 'features/new-capital-call/redux/capitalCallRequests.slice'
import { capitalCallSlice } from 'features/capital_call/redux/capital_calls.slice'
import { capitalCallTemplateslice } from 'features/settings/CapitalCallTemplates/redux/capital_call_template.slice'
import { columnsSlice } from 'features/columns/column.slice'
import { companiesSlice } from 'features/companies/redux/companies.slice'
import { companySlice } from 'features/companies/redux/company.slice'
import { contactSlice } from 'features/contacts/redux/contact.slice'
import { contactsSlice } from 'features/contacts/redux/contacts.slice'
import { conversationSlice } from 'features/companies/redux/conversation.slice'
import { cryptoAccountsSlice } from 'features/crypto_accounts/redux/cryptoAccounts.slice'
import { customColumnSlice } from 'features/dataTable/hooks/customColumn.slice'
import { customFieldSlice } from 'features/customfields/customFields.slice'
import { dashboardHomeSlice } from 'features/dashboard/redux/dashboardHome.slice'
import { dashboardSlice } from 'features/dashboard/dashboard.slice'
import { draftNotesSlice } from 'features/notes/redux/draft_notes.slice'
import { emailSignaturesSlice } from 'features/mail/redux/emailSignatures.slice'
import { filesSlice } from 'features/files/files.slice'
import { funnelSlice } from 'features/funnels/funnels.slice'
import { industriesSlice } from 'features/industries/redux/industries.slice'
import { investmentsSlice } from 'features/investments/redux/investments.slice'
import { invitationSlice } from 'features/invitations/redux/invitation.slice'
import { invitationsSlice } from 'features/invitations/redux/invitations.slice'
import { lpSlice } from 'features/limited_partners/redux/lp.slice'
import { lpsSlice } from 'features/limited_partners/redux/lps.slice'
import { mailComposeSlice } from 'features/mail/redux/mailcompose.slice'
import { messagesSlice } from 'features/mail/redux/emails.slice'
import { newCapitalCallSlice } from 'features/new-capital-call/redux/newCapitalCall.slice'
import { noteSettingsSlice } from 'features/settings/Notes/redux/note_settings.slice'
import { noteSlice } from 'features/notes/redux/note.slice'
import { notesSlice } from 'features/notes/redux/notes.slice'
import { notificationsSlice } from 'features/notifications/redux/notifications.slice'
import { onboardingSlice } from 'features/onboarding/redux/onboarding.slice'
import { partnerSlice } from 'features/partners/partnerSlice'
import { prospectsSlice } from 'features/prospects/prospects.slice'
import { setupListeners } from '@reduxjs/toolkit/query'
import { spacesSlice } from 'features/spaces/redux/spaces.slice'
import { tagSlice } from 'features/tags/tags.slice'
import { taskSlice } from 'features/tasks/redux/task.slice'
import { tasksSlice } from 'features/tasks/redux/tasks.slice'
import { threadsSlice } from 'features/mail/redux/threads.slice'
import { updatesSlice } from 'features/updates/redux/updates.slice'
import { wiseTransactionsSlice } from 'features/wise/redux/wiseTransactions.slice'
import { aiReportsSlice } from 'features/reports/redux/aireports.slice'

const rootReducer = combineSlices(
	authenticationSlice,
	invitationsSlice,
	conversationSlice,
	invitationSlice,
	partnerSlice,
	companiesSlice,
	companySlice,
	contactsSlice,
	contactSlice,
	tasksSlice,
	taskSlice,
	tagSlice,
	lpsSlice,
	aiReportsSlice,
	lpSlice,
	notesSlice,
	noteSlice,
	funnelSlice,
	customFieldSlice,
	prospectsSlice,
	threadsSlice,
	messagesSlice,
	mailComposeSlice,
	investmentsSlice,
	dashboardSlice,
	filesSlice,
	columnsSlice,
	activitySlice,
	calendarEventsSlice,
	updatesSlice,
	newCapitalCallSlice,
	bankAcountsSlice,
	cryptoAccountsSlice,
	capitalCallSlice,
	capitalCallTemplateslice,
	capitalCallRequestsSlice,
	spacesSlice,
	notificationsSlice,
	appStatusSlice,
	dashboardHomeSlice,
	noteSettingsSlice,
	industriesSlice,
	wiseTransactionsSlice,
	customColumnSlice,
	draftNotesSlice,
	onboardingSlice,
	emailSignaturesSlice
)

export type RootState = ReturnType<typeof rootReducer>

// const persistConfig = {
// 	key: 'root',
// 	storage
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)

export const makeStore = (preloadedState?: Partial<RootState>) => {
	const store = configureStore({
		reducer: rootReducer,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				immutableCheck: false,
				serializableCheck: false
			}),
		preloadedState
	})
	setupListeners(store.dispatch)

	return store
}

export const store = makeStore()

// export const persistor = persistStore(store)

// Infer the type of `store`
export type AppStore = typeof store
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ThunkReturnType = void> = ThunkAction<
	ThunkReturnType,
	RootState,
	unknown,
	Action
>
