import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ButtonSliceState {
  selectedButton: string | null;
  selectedTab: string | null
}

const initialState: ButtonSliceState = {
  selectedButton: null,
  selectedTab: 'info'
};

export const customColumnSlice = createSlice({
  name: 'button',
  initialState,
  reducers: {
    setSelectedButton: (state, action: PayloadAction<string>) => {
      state.selectedButton = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { setSelectedButton, setSelectedTab } = customColumnSlice.actions;

export const selectSelectedButton = (state) => state.button.selectedButton;

export const selectSelectedTab = (state) => state.button.selectedTab;

export default customColumnSlice.reducer;
