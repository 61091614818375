/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthContext } from 'context/AuthContext'
import { useFormik } from 'formik'
import { nanoid } from 'nanoid'
import { useContext, useEffect } from 'react'
import * as Yup from 'yup'
import type { ITask } from '../interafces/task.interface'

export default function useCreateTask({
	onCreateTask,
	task
}: {
	onCreateTask: (task: ITask) => void
	task?: ITask
}) {
	const { authUser } = useContext(AuthContext)

	const formik = useFormik({
		initialValues: {
			task: task?.description || '',
			taskRawPlate: task?.taskRawPlate || [],
			executor: task?.executor,
			status: task?.status || 'backlog',
			dueDate: task?.dueDate || new Date().toISOString(),
			creator: task?.creator || null,
			createdAt: task?.createdAt || new Date().toISOString(),
			company: task?.company,
			fund: task?.fund || null,
			mail: task?.mail || null
		},

		validationSchema: Yup.object({
			task: Yup.string().required('Note is required')
			// taskRawPlate: Yup.array().required('Note is required')
		}),
		onSubmit: async values => {
			if (!authUser) return

			const data: ITask = {
				id: task?.id ?? nanoid(),
				fund: values.fund ?? authUser.fund,
				description: values.task,
				status: values.status || 'backlog',
				dueDate: values.dueDate || new Date().toISOString(),
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				taskRawPlate: values.taskRawPlate || [],
				createdAt: values.createdAt || new Date().toISOString(),
				executor: {
					id: values.executor?.id || authUser.id,
					name: values.executor?.name || authUser.name,
					photoUrl: values.executor?.photoUrl || authUser.photoUrl || ''
				},
				lastUpdatedAt: new Date().toISOString(),
				creator: values.creator ?? {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl ?? ''
				}
			}

			if (values.executor) data.executor = values.executor

			if (values.company) data.company = values.company

			if (values.mail) data.mail = values.mail

			console.log('data', data)

			onCreateTask(data)
			formik.resetForm()
		}
	})

	useEffect(() => {
		formik.setValues({
			...task,
			task: task?.description || '',
			dueDate: task?.dueDate || new Date().toISOString()
		} as never)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { formik }
}
