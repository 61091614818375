import LpSidebar from 'components/Dashboard/LpSidebar'
import Sidebar from 'components/Dashboard/Sidebar'
import { UserRole } from 'interfaces'
import type { ReactNode } from 'react'

export function getSidebar(role: UserRole): ReactNode {
	switch (role) {
		case UserRole.LimitedPartner:
		case UserRole.TeamMember: {
			return <LpSidebar />
		}
		default: {
			return <Sidebar />
		}
	}
}
