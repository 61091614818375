import { useAppSelector } from 'app/hooks'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import {
	selectAuthUser,
	selectAuthUserFund,
	selectCurrencyOptions
} from 'features/authentication/authentication.slice'
import type React from 'react'
// import { useContext } from 'react'
import { CurrencyExchangeService } from '../service/currency_exchange.service'
// import { FeatureAccessContext } from 'features/authorization/context/FeatureAccessProvider'
// import { Feature } from 'features/authorization/interfaces/feature.interface'
import { UserRole } from 'interfaces'

// eslint-disable-next-line react/function-component-definition
const ExchangerUI: React.FC = () => {
	const authUser = useAppSelector(selectAuthUser)
	const fund = useAppSelector(selectAuthUserFund)
	const currencyOptions = useAppSelector(selectCurrencyOptions)
	// const { hasReadOnlyAccess } = useContext(FeatureAccessContext)

	if (!fund) return null

	if (authUser?.role === UserRole.LimitedPartner) return null

	return (
		<div>
			<ComboSelect
				label=''
				// isReadOnly={hasReadOnlyAccess(Feature.CurrencyExchange)}
				isReadOnly={false}
				onSelect={value => {
					CurrencyExchangeService.updateFundCurrency(value)
				}}
				defaultValue={fund.currency}
				options={currencyOptions}
				content={
					<div className='mr-3 text-base font-semibold text-blue-700'>
						{fund.currency}
					</div>
				}
			/>
		</div>
	)
}

export default ExchangerUI
