

export function renderTime(date: Date | string) {
	try {
		if (!date) return '-'
		return new Intl.DateTimeFormat('en-US', {
			hour: 'numeric',
			minute: 'numeric'
		}).format(new Date(date))
	} catch {
		return '-'
	}
}

export function renderDate(date: Date | string) {
	try {
		if (!date) return '-'

		// If time is less than 24 hours, return time only
		const timeAgo = new Date(date).getTime()
		const now = Date.now()
		const diff = now - timeAgo
		if (diff < 86_400_000) {
			return renderTime(date)
		}

		return new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: 'short',
			day: '2-digit'
		}).format(new Date(date))
	} catch {
		return '-'
	}
}

export function renderDayAndMonth(date: Date | string) {
	try {
		if (!date) return '-'
		return new Intl.DateTimeFormat('en-US', {
			month: 'short',
			day: '2-digit'
		}).format(new Date(date))
	} catch {
		return '-'
	}
}

export function renderDateAndTime(date: Date | string) {
	try {
		if (!date) return '-'
		return new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: 'short',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric'
		}).format(new Date(date))
	} catch {
		return '-'
	}
}

export function renderDateOnly(date: Date | string) {
	try {
		if (!date) return '-'
		return new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: 'short',
			day: '2-digit'
		}).format(new Date(date))
	} catch {
		return '-'
	}
}



// Function to format seconds into HH:MM:SS format
export const formatTime = (timeInSeconds: number) => {
	const hours = Math.floor(timeInSeconds / 3600)
	const minutes = Math.floor((timeInSeconds % 3600) / 60)
	const seconds = timeInSeconds % 60
	return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''
		}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

export function getMonthAndYear(date: string) {
	const dateObj = new Date(date)
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]
	const monthIndex = dateObj.getMonth()
	const year = dateObj.getFullYear()

	return `${months[monthIndex]} ${year}`
}

export function getMonthName(month: number) {
	if (month < 1 || month > 12) {
		return "Invalid month number";
	}

	const date = new Date(2024, month - 1, 1);
	return date.toLocaleString("en-US", { month: "long" });
}


export function getUniqueYearsFromIsoStrings(dateStrings: string[]): number[] {
  const yearSet = new Set<number>();
  for (const dateString of dateStrings) {
      const dateObject = new Date(dateString);
      const year = dateObject.getFullYear(); 
      yearSet.add(year);
  }
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(yearSet);
}


export function getUniqueMonthsFromIsoStrings(dateStrings: string[]): number[] {
  const monthsSet = new Set<number>();
  for (const dateString of dateStrings) {
      const dateObject = new Date(dateString);
      const year = dateObject.getMonth(); 
      monthsSet.add(year);
  }
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(monthsSet);
}
