import type { UserRole } from 'interfaces'
import type { Feature } from './feature.interface'

export enum AccessType {
	Deny = 'Deny',
	ReadOnly = 'read-only',
	ReadWrite = 'read-write'
}

export type FeatureAccess = Record<Feature, AccessType>
export type PartialFeatureAccess = Partial<FeatureAccess>

export interface RouteAccess {
	roles: UserRole[] | 'all-roles'
	features: PartialFeatureAccess
}
