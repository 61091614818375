/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable @typescript-eslint/prefer-destructuring */
import * as Yup from 'yup'

import type { IDraftNote, INote } from '../interfaces/notes.interface'
import { NoteEditorState, NoteType } from '../interfaces/notes.interface'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthContext } from 'context/AuthContext'
import { DraftNoteService } from '../services/draft_notes.service'
import type { FundData } from 'interfaces'
import { NoteService } from '../services/notes.service'
import { nanoid } from 'nanoid'
import { useContext } from 'react'
import { useFormik } from 'formik'
import { useLocation } from 'react-router-dom'
import { store } from 'app/store'
import { addNotes, removeNote } from '../redux/notes.slice'

interface Props {
	closeWindow?: () => void
	note?: IDraftNote | INote
}

export function useCreateNote({ closeWindow, note }: Props) {
	const { authUser } = useContext(AuthContext)
	const location = useLocation()

	const defaultTitle = `${authUser?.name}'s note from ${new Date().toLocaleDateString()}`

	const getDefaultType = () => {
		const { pathname } = location
		const page = pathname.split('/')[1]

		switch (page) {
			case 'company': {
				return NoteType.Company
			}
			case 'companies': {
				return NoteType.Company
			}
			case 'contact': {
				return NoteType.Contact
			}
			case 'contacts': {
				return NoteType.Contact
			}
			case 'limited-partner': {
				return NoteType.LimitedPartner
			}
			case 'limited-partners': {
				return NoteType.LimitedPartner
			}
			case 'tasks': {
				return NoteType.Meeting
			}
			default: {
				return NoteType.Company
			}
		}
	}

	const formik = useFormik({
		initialValues: {
			note: note?.note,
			windowState: note?.windowState || NoteEditorState.RESTORED,
			title: note?.title || defaultTitle,
			company: note?.company,
			limitedPartner: note?.limitedPartner,
			contact: note?.contact,
			date: note?.date ?? new Date().toISOString(),
			noteType: note?.noteType ?? getDefaultType(),
			creator: note?.creator ?? {
				id: authUser?.id || '',
				name: authUser?.name || '',
				photoUrl: authUser?.photoUrl ?? ''
			},
			fund: note?.fund ?? (authUser?.fund as FundData)
		},
		validationSchema: Yup.object({
			note: Yup.string().required('Note is required'),
			title: Yup.string().required('Title is required')
		}),

		onSubmit: async values => {
			// Validation
			// toast.loading('Creating note...')

			const { noteType, company, limitedPartner, contact } = values

			if (noteType === NoteType.Company && !company) {
				alert('Please select a company')
				// toast.dismiss()
				return
			}
			if (noteType === NoteType.LimitedPartner && !limitedPartner) {
				alert('Please select a limited partner')
				// toast.dismiss()
				return
			}
			if (noteType === NoteType.Contact && !contact) {
				alert('Please select a contact')
				// toast.dismiss()
				return
			}

			if (!authUser) return

			const data: INote = {
				id: note?.id ?? nanoid(),
				note: values.note ?? '',
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				title: values.title ?? '',
				creator: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl ?? ''
				},
				fund: authUser.fund,
				isDraft: false,
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				windowState: values.windowState ?? NoteEditorState.CLOSED,
				createdAt: note?.createdAt ?? new Date().toISOString(),
				lastUpdatedAt: new Date().toISOString(),
				type: '',
				date: values.date,
				noteType: values.noteType,
				isPinned: note?.isPinned ?? false
			}

			if (values.company && values.noteType === NoteType.Company) {
				data.company = values.company
			}

			if (
				values.limitedPartner &&
				values.noteType === NoteType.LimitedPartner
			) {
				data.limitedPartner = values.limitedPartner
			}

			if (values.contact && values.noteType === NoteType.Contact) {
				data.contact = values.contact
			}

			store.dispatch(addNotes([data]))

			closeWindow?.()

			try {
				const result = await (note?.createdAt
					? NoteService.updateNote(data)
					: NoteService.addNote(data))

				if (result) {
					DraftNoteService.deleteDratNote(note?.id ?? '')
				} else {
					store.dispatch(removeNote(data.id))
				}
			} catch {
				store.dispatch(removeNote(data.id))
			}

			formik.resetForm()
		}
	})

	return {
		formik
	}
}
