import { BriefcaseBusiness, Home, PieChart } from 'lucide-react'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import { SpacesService } from 'features/spaces/service/spaces.service'
import clsxm from 'utils/clsxm'
import { SidebarItem } from './Sidebar'

export default function LpSidebar(): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [sidebarState, setSidebarState] = useState<
		'close' | 'minimize' | 'open'
	>('open')
	const { authUser } = useContext(AuthContext)

	useEffect(() => {
		if (!authUser?.fund.id) return
		SpacesService.listenToSpaces({ fundId: authUser.fund.id })
	}, [authUser?.fund.id])

	return (
		<div
			data-testid='lp-sidebar'
			className={clsxm(
				'fixed h-[calc(100vh-3rem)]',
				`z-[8] flex flex-col justify-between overflow-y-auto overflow-x-clip border-r`,
				sidebarState === 'minimize' ? 'w-[3.8rem]' : 'w-[12.2rem]'
			)}
		>
			<div className='flex flex-col pt-5'>
				{/* <SidebarItem name='Home' url='/dashboard' icon={<Home size={15} />} /> */}

				<SidebarItem name='Profile' url='/_' icon={<Home size={15} />} />
				<SidebarItem
					name='Companies'
					url='/_companies'
					icon={<BriefcaseBusiness size={15} />}
				/>

				<SidebarItem
					name='Reports'
					url='/reports'
					icon={<PieChart size={15} />}
				/>
				{/* <SidebarItem name='Profile' url='/profile' icon={<User size={15} />} /> */}
			</div>
		</div>
	)
}
