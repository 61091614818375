/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
import clsx from 'clsx'
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar'
import type { ICalendarEvent } from 'features/companies/interfaces/calendarEvent.interface'
import { renderDateAndTime } from 'utils/date.util'
import { firestore } from 'lib/firebase'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import type { ICompany, ILimitedPartner } from 'interfaces'
import { Link } from 'react-router-dom'
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'
import Logger from 'lib/logger'

function OngoingEvents({ ongoingEvents }: { ongoingEvents: ICalendarEvent[] }) {
	const companyReference = collection(firestore, 'companies')
	const lpReference = collection(firestore, 'lps')
	const [attendeeCompanies, setAttendeeCompanies] = useState<any>({})
	const [attendeeLP, setAttendeeLP] = useState<any>({})

	useEffect(() => {
		const fetchCompanyData = async () => {
			const newAttendeeCompanies: Record<string, any[]> = {}
			const newAttendeeLP: Record<string, any[]> = {}

			// Get all attendees from future events
			const attendees = ongoingEvents.flatMap(event => event.attendees)

			// Perform all Firestore queries concurrently for each attendee
			const promises = attendees.map(async attendee => {
				// Define the queries for both company and LP
				const companyQuery = query(
					companyReference,
					where('founderEmails', 'array-contains', attendee.email)
				)
				const lpQuery = query(lpReference, where('email', '==', attendee.email))

				try {
					// Run both queries concurrently
					const [companySnapshot, lpSnapshot] = await Promise.all([
						getDocs(companyQuery),
						getDocs(lpQuery)
					])

					// Store the company data
					newAttendeeCompanies[attendee.email] = companySnapshot.docs.map(doc =>
						doc.data()
					)

					// Store the LP data
					newAttendeeLP[attendee.email] = lpSnapshot.docs.map(doc => doc.data())
				} catch (error) {
					Logger.error(`Error fetching data for ${attendee.email}:`, error)
				}
			})

			// Wait for all promises to resolve
			await Promise.all(promises)

			// Update state with all the fetched data at once
			setAttendeeCompanies(newAttendeeCompanies)
			setAttendeeLP(newAttendeeLP)
		}

		fetchCompanyData()
	}, [companyReference, ongoingEvents, lpReference])

	return (
		<div className='my-3 rounded-md border p-3'>
			<div className='flex items-center gap-1'>
				<div className={clsx('h-3 w-3 rounded-full bg-blue-500')} />
				<div className='text-sm font-semibold text-blue-700'>
					Happening Now
				</div>
			</div>
			{ongoingEvents.map(event => (
				<div
					key={event.id}
					className='mb-3 flex flex-col items-center gap-2 rounded-md border-b p-2'
				>
					<div className='flex w-full items-center justify-start gap-2'>
						<Avatar className='h-8 w-8 rounded-full border'>
							{event.user.photoUrl && (
								<AvatarImage
									src={event.user.photoUrl || '/images/logo/Logo-4.png'}
								/>
							)}
							<AvatarFallback>{event.user.name[0]}</AvatarFallback>
						</Avatar>
						<div className='line-clamp-2 flex-1 text-sm font-semibold text-blue-700'>
							{event.summary}
						</div>
						<div className='text-xs font-semibold text-gray-600'>
							{renderDateAndTime(
								event.start.dateTime || event.start.date || ''
							)}
						</div>
					</div>
					<div className='w-full'>
						{event.attendees.map(attendee => (
							<div key={attendee.email} className='my-1 text-xs'>
								<div className='flex items-center gap-2'>
									<span>{attendee.email}</span>
									{attendeeCompanies[attendee.email]?.map(
										(company: ICompany) => (
											<Link
												to={`/company/${company.id}`}
												className=''
												key={company.id}
											>
												<Tooltip key={company.id}>
													<TooltipTrigger asChild>
														<Avatar className='h-6 w-6 cursor-pointer border'>
															<AvatarImage
																src={
																	company.website
																		? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.website}`
																		: '/images/logo/Logo-4.png'
																}
															/>
															<AvatarFallback>
																{company.name.charAt(0)}
															</AvatarFallback>
														</Avatar>
													</TooltipTrigger>
													<TooltipContent>View {company.name}</TooltipContent>
												</Tooltip>
											</Link>
										)
									)}
									{attendeeLP[attendee.email]?.map((lp: ILimitedPartner) => (
										<Link to={`/prospects/${lp.id}`} className='' key={lp.id}>
											<Tooltip key={lp.id}>
												<TooltipTrigger asChild>
													<Avatar className='h-6 w-6 cursor-pointer border'>
														<AvatarImage
															src={lp.photoUrl ?? '/images/logo/Logo-4.png'}
														/>
														<AvatarFallback>{lp.name.charAt(0)}</AvatarFallback>
													</Avatar>
												</TooltipTrigger>
												<TooltipContent>View {lp.name}</TooltipContent>
											</Tooltip>
										</Link>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	)
}

export default OngoingEvents
