/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { IAiReport } from '../interfaces/reports.interface'

export interface NoteSliceState {
	reports: IAiReport[]
	isLoading: boolean
	error?: string | null
}

const initialState: NoteSliceState = {
	reports: [],
	isLoading: false,
	error: null
}

export const aiReportsSlice = createAppSlice({
	name: 'ai-reports',
	initialState,
	reducers: create => ({
		setAIReportsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setAIReportsError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setAIReports: create.reducer(
			(state, action: PayloadAction<IAiReport[]>) => {
				state.isLoading = false
				state.reports = action.payload
			}
		)
	}),
	selectors: {
		selectAIReports: state => state.reports,
		selectAIReportsLoading: state => state.isLoading
	}
})

export const { setAIReports, setAIReportsLoading } = aiReportsSlice.actions

export const { selectAIReports, selectAIReportsLoading } =
	aiReportsSlice.selectors
