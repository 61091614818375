import { createContext, useMemo, useState } from 'react'
import type { ReactNode, SetStateAction, Dispatch } from 'react'

import { Feature } from '../interfaces/feature.interface'
import type { PartialFeatureAccess } from '../interfaces/access.interface'
import { AccessType } from '../interfaces/access.interface'

interface FeatureAccessContextProps {
	permissions: PartialFeatureAccess
	getAccess: (feature: Feature) => AccessType
	setPermissions: Dispatch<SetStateAction<PartialFeatureAccess>>
	hasReadOnlyAccess: (feature: Feature) => boolean
	hasNoAccess: (feature: Feature) => boolean
	hasWriteAccess: (feature: Feature) => boolean
}

export const FeatureAccessContext = createContext<FeatureAccessContextProps>({
	permissions: {},
	setPermissions(): void {
		throw new Error('Function not implemented.')
	},
	getAccess: () => AccessType.ReadWrite,
	hasReadOnlyAccess: () => true,
	hasNoAccess: () => true,
	hasWriteAccess: () => true
})

interface FeatureAccessContextProviderArg {
	children: ReactNode
}

function FeatureAccessContextProvider({
	children
}: Readonly<FeatureAccessContextProviderArg>) {
	const [permissions, setPermissions] = useState<PartialFeatureAccess>({})

	function getAccess(feature: Feature) {
		const accessType = permissions[feature] ?? permissions[Feature.All]
		return accessType ?? AccessType.Deny
	}

	function hasReadOnlyAccess(feature: Feature) {
		return getAccess(feature) === AccessType.ReadOnly
	}

	function hasNoAccess(feature: Feature) {
		return getAccess(feature) === AccessType.Deny
	}

	function hasWriteAccess(feature: Feature) {
		return getAccess(feature) === AccessType.ReadWrite
	}

	const defaultValue = useMemo(() => {
		return {
			permissions,
			setPermissions,
			getAccess,
			hasNoAccess,
			hasReadOnlyAccess,
			hasWriteAccess
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [permissions, setPermissions])

	return (
		<FeatureAccessContext.Provider value={defaultValue}>
			{children}
		</FeatureAccessContext.Provider>
	)
}

export default FeatureAccessContextProvider
