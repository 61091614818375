/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-destructuring */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { cn } from 'lib/utils'
import * as React from 'react'
import { useImperativeHandle } from 'react'

interface UseAutosizeTextAreaProps {
	textAreaRef: HTMLTextAreaElement | null
	minHeight?: number
	maxHeight?: number
	triggerAutoSize: string
}

export const useAutosizeTextArea = ({
	textAreaRef,
	triggerAutoSize,
	maxHeight = Number.MAX_SAFE_INTEGER,
	minHeight = 0
}: UseAutosizeTextAreaProps) => {
	const [init, setInit] = React.useState(true)
	React.useEffect(() => {
		// We need to reset the height momentarily to get the correct scrollHeight for the textarea
		const offsetBorder = 2
		if (textAreaRef) {
			if (init) {
				textAreaRef.style.minHeight = `${minHeight + offsetBorder}px`
				if (maxHeight > minHeight) {
					textAreaRef.style.maxHeight = `${maxHeight}px`
				}
				setInit(false)
			}
			textAreaRef.style.height = `${minHeight + offsetBorder}px`
			const scrollHeight = textAreaRef.scrollHeight
			// We then set the height directly, outside of the render loop
			// Trying to set this with state or a ref will product an incorrect value.
			textAreaRef.style.height =
				scrollHeight > maxHeight
					? `${maxHeight}px`
					: `${scrollHeight + offsetBorder}px`
		}
	}, [textAreaRef, triggerAutoSize])
}

export interface AutosizeTextAreaRef {
	textArea: HTMLTextAreaElement
	maxHeight: number
	minHeight: number
}

type AutosizeTextAreaProps =
	React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
		maxHeight?: number
		minHeight?: number
	}

export const AutosizeTextarea = React.forwardRef<
	AutosizeTextAreaRef,
	AutosizeTextAreaProps
>(
	(
		{
			maxHeight = Number.MAX_SAFE_INTEGER,
			minHeight = 52,
			className,
			onChange,
			value,
			...props
		}: AutosizeTextAreaProps,
		ref: React.Ref<AutosizeTextAreaRef>
	) => {
		const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null)
		const [triggerAutoSize, setTriggerAutoSize] = React.useState('')

		useAutosizeTextArea({
			textAreaRef: textAreaRef.current,
			triggerAutoSize,
			maxHeight,
			minHeight
		})

		useImperativeHandle(ref, () => ({
			textArea: textAreaRef.current as HTMLTextAreaElement,
			focus: () => textAreaRef.current?.focus(),
			maxHeight,
			minHeight
		}))

		React.useEffect(() => {
			if (value || props?.defaultValue) {
				setTriggerAutoSize(value as string)
			}
		}, [value || props?.defaultValue])

		return (
			<textarea
				{...props}
				data-testid={props.id}
				value={value}
				ref={textAreaRef}
				className={cn(
					'border-input bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex w-full rounded-md border px-3 py-2 text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
					className
				)}
				onChange={e => {
					setTriggerAutoSize(e.target.value)
					onChange?.(e)
				}}
			/>
		)
	}
)
AutosizeTextarea.displayName = 'AutosizeTextarea'
