/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { IInvestment } from '../interfaces/investment.interface'

export interface InvestmentsSliceState {
	data: IInvestment[]
	isLoading: boolean
	error?: string | null
}

const initialState: InvestmentsSliceState = {
	data: [],
	error: null,
	isLoading: false
}

export const investmentsSlice = createAppSlice({
	name: 'investments',
	initialState,
	reducers: create => ({
		setInvestmentsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setInvestmentsError: create.reducer(
			(state, action: PayloadAction<string>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setInvestments: create.reducer(
			(state, action: PayloadAction<IInvestment[]>) => {
				state.data = action.payload
				state.isLoading = false
			}
		)
	}),
	selectors: {
		selectInvestments: investment => investment.data,
		selectInvestmentsLoading: investment => investment.isLoading
	}
})

export const { setInvestmentsError, setInvestmentsLoading, setInvestments } =
	investmentsSlice.actions

export const { selectInvestments, selectInvestmentsLoading } =
	investmentsSlice.selectors
