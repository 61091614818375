import type React from 'react'
import {
	memo,
	useCallback,
	useMemo,
	useState,
	useContext,
	useEffect
} from 'react'
import clsx from 'clsx'
import { Input } from 'components/ui/input'
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue
} from 'components/ui/select'
import type { PartnerData } from 'interfaces'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { fetchPartners, selectPartners } from 'features/partners/partnerSlice'
import { AuthContext } from 'context/AuthContext'

interface Props {
	onSelect: (value: PartnerData) => void
	defaultValue?: PartnerData
	placeholder?: string
	isCombo?: boolean
	content?: React.ReactNode
	groupName?: string
	className?: string
	label?: string
	allowSearch?: boolean
	searchPlaceHolder?: string
	isReadOnly?: boolean
}

const PartnerSelector = memo(
	({
		defaultValue,
		onSelect,
		placeholder,
		label,
		allowSearch = false,
		searchPlaceHolder,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		isCombo = true,
		content,
		groupName,
		className = 'w-[280px]',
		isReadOnly
	}: Props) => {
		const { authUser } = useContext(AuthContext)
		const dispatch = useAppDispatch()
		const partners = useAppSelector(selectPartners)
		const [searchTerm, setSearchTerm] = useState('')

		const memoizedPartners = useMemo(() => partners, [partners])

		const filteredPartners = useMemo(() => {
			if (!searchTerm) return memoizedPartners
			return memoizedPartners.filter(option =>
				option.name.toLowerCase().includes(searchTerm.toLowerCase())
			)
		}, [memoizedPartners, searchTerm])

		const onSearchChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				setSearchTerm(e.target.value)
			},
			[]
		)

		// Listen to partners
		useEffect(() => {
			if (authUser?.fund.id) {
				dispatch(fetchPartners(authUser.fund.id))
			}
		}, [dispatch, authUser?.fund.id])

		if (isReadOnly) {
			return (
				<div>
					{label && (
						<div className='mb-2 block text-xs font-bold text-gray-700'>
							{label}
						</div>
					)}
					<div className='group flex w-fit items-center gap-1'>
						{memoizedPartners.find(option => option.id === defaultValue?.id)
							?.name || '-'}
					</div>
				</div>
			)
		}

		return (
			<div>
				{label && (
					<div className='mb-2  block text-xs font-bold text-gray-700'>
						{label}
					</div>
				)}
				<Select
					defaultValue={defaultValue?.id}
					onValueChange={e => {
						const partner = memoizedPartners.find(_partner => _partner.id === e)
						onSelect(partner as PartnerData)
					}}
				>
					{content ? (
						<SelectTrigger
							defaultValue='onlyContent'
							className='border-none border-transparent shadow-none'
						>
							{content}
						</SelectTrigger>
					) : (
						<SelectTrigger className={clsx(className)}>
							<SelectValue placeholder={placeholder} />
						</SelectTrigger>
					)}
					<SelectContent className='z-[999999]'>
						{allowSearch && (
							<Input
								placeholder={searchPlaceHolder ?? 'Search'}
								className='mb-2'
								onChange={onSearchChange}
							/>
						)}

						{filteredPartners.length === 0 && (
							<div className='text-center text-gray-500'>No results found</div>
						)}

						<SelectGroup>
							{groupName && <SelectLabel>{groupName}</SelectLabel>}
							{filteredPartners.map(option => (
								<SelectItem key={option.id} value={option.id}>
									{option.name}
								</SelectItem>
							))}
						</SelectGroup>
					</SelectContent>
				</Select>
			</div>
		)
	}
)

PartnerSelector.displayName = 'PartnerSelector'

export { PartnerSelector }
