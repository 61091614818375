import { UserRole } from 'interfaces'
import type { PartialFeatureAccess } from '../interfaces/access.interface'
import { AccessType } from '../interfaces/access.interface'
import { Feature } from '../interfaces/feature.interface'

export const FEATURE_PERMISSIONS: Record<UserRole, PartialFeatureAccess> = {
	[UserRole.Admin]: {
		[Feature.All]: AccessType.ReadWrite
	},
	[UserRole.OpsManager]: {
		[Feature.All]: AccessType.ReadWrite
	},
	[UserRole.VenturePartner]: {
		[Feature.All]: AccessType.ReadWrite
	},
	[UserRole.LimitedPartner]: {
		[Feature.All]: AccessType.ReadOnly,
		[Feature.PortfolioCompaniesRoute]: AccessType.ReadOnly,
		[Feature.CapitalCalls]: AccessType.ReadOnly,
		[Feature.TeamMembers]: AccessType.ReadWrite,
		[Feature.Notes]: AccessType.Deny,
		[Feature.CompanyDeck]: AccessType.ReadOnly,
		[Feature.Files]: AccessType.Deny,
		[Feature.LogAction]: AccessType.Deny,
		[Feature.Email]: AccessType.Deny,
		[Feature.ContactsRoute]: AccessType.Deny
	},
	[UserRole.GeneralPartner]: {
		[Feature.All]: AccessType.ReadWrite
	},
	[UserRole.TeamMember]: {
		[Feature.All]: AccessType.ReadOnly,
		[Feature.PortfolioCompaniesRoute]: AccessType.ReadOnly,
		[Feature.CapitalCalls]: AccessType.ReadOnly,
		[Feature.TeamMembers]: AccessType.ReadWrite,
		[Feature.Notes]: AccessType.Deny,
		[Feature.CompanyDeck]: AccessType.ReadOnly,
		[Feature.Files]: AccessType.Deny,
		[Feature.LogAction]: AccessType.Deny,
		[Feature.Email]: AccessType.Deny,
		[Feature.ContactsRoute]: AccessType.Deny
	},
	[UserRole.SuperAdmin]: {
		[Feature.All]: AccessType.ReadWrite
	}
}
