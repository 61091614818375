/* eslint-disable react/destructuring-assignment */
import clsxm from 'utils/clsxm'

import { useNavigate } from 'react-router-dom'
import Button from './Buttons/Button'

interface INotFoundProps {
	title?: string
	description?: React.ReactNode
	callToActionText?: string
	onCallToActionClick?: () => void
}

export function NotFound({
	title = 'Page not found',
	description = "The page you're looking for does not exist",
	callToActionText = 'Go home',
	onCallToActionClick
}: Readonly<INotFoundProps>) {
	const navigate = useNavigate()

	return (
		<div
			className={clsxm(
				'h-[300px] p-4 text-center',
				'flex flex-col items-center justify-center gap-2'
			)}
			data-testid='not-found'
		>
			<h1 className={clsxm('text-3xl font-semibold')}>{title}</h1>
			<p className='mb-4 mt-2 max-w-sm'>{description}</p>
			<Button
				type='button'
				variant='blue'
				className='px-4 py-2'
				onClick={
					onCallToActionClick ?? (() => navigate('/dashboard?update=all'))
				}
			>
				{callToActionText}
			</Button>
		</div>
	)
}

export default NotFound
