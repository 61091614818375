/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { ISpace } from '../interface/spaces.interface'

export interface State {
	data: ISpace[]
	isLoading: boolean
	error?: string | null
}

const initialState: State = {
	data: [],
	isLoading: true,
	error: null
}

export const spacesSlice = createAppSlice({
	name: 'spaces',
	initialState,
	reducers: create => ({
		setSpaces: create.reducer((state, action: PayloadAction<ISpace[]>) => {
			state.data = action.payload
			state.isLoading = false
		}),
		setLoadingSpaces: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		)
	}),
	selectors: {
		selectSpaces: spaces => spaces,
		selectCompanySpaces: spaces =>
			spaces.data.filter(space => space.workspace === 'companies'),
		selectSpaceLoadingState: space => space.isLoading
	}
})

export const { setSpaces, setLoadingSpaces } = spacesSlice.actions

export const { selectSpaces, selectCompanySpaces, selectSpaceLoadingState } =
	spacesSlice.selectors
